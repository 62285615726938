<script setup lang="ts">
import { onMounted } from "vue";
import { ref } from "vue";
const theme = ref("light");

function toggle() {
    let newMode = theme.value == "light" ? "dark" : "light";
    theme.value = newMode;
    document.cookie = "docs-theme=" + newMode;
    window.location.reload();
}

onMounted(() => {
    if (document.cookie.includes('docs-theme')) {
        let mode = document.cookie.split(';')
            .find(c => c.includes('docs-theme'))
            ?.split('=')[1];
        if (mode) {
            theme.value = mode;
            document.documentElement.setAttribute('data-bs-theme', mode);
        }
    }
});
</script>

<template>
    <div @click="toggle">
        <i class="bi bi-moon me-1" :class="{'bi-sun': theme == 'dark'}" style="font-size: 1.2em!important;"></i>
        <span>{{ theme != "dark" ? "Dark mode" : "Light mode" }}</span>
    </div>
</template>